<template>
    <v-container fluid class="mt-1 mb-12">
        <v-row>
            <v-col cols="12">
                <breadcrumb :breadcumbs="breadcumbs"></breadcrumb>
            </v-col>
        </v-row>
        <v-row v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`">
            <v-col
                class="col-6"
                xs="12" 
                sm="5" 
                md="3"
                v-for="item in items" :key="item.name"
            >
                <v-skeleton-loader
                class="mx-auto"
                type="card, card-text"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-6" sm="4" md="4" lg="3" v-for="(menu_item, i) in menus" :key="i">
                <card-menu-item :menu_item="menu_item"></card-menu-item>
            </v-col>
        </v-row>
    </v-container>
  </template>
  <script>
  import { mapState } from 'vuex'
  import {menu} from "@/backend-api/menu/index" 
  import Breadcrumb from "@/components/Breadcrumb.vue"
  import CardMenuItem from "@/components/CardMenuItem.vue"

  export default {
    components: {
        Notification, Breadcrumb, CardMenuItem
    },
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            text_dialog:'',
            dialog: false,
            breadcumbs: [
            {
                text: 'User Management',
                disabled: false,
                href: '/admin/master',
            },
            {
                text: 'Master',
                disabled: true,
            }
            ],
            menus: [],
            items: [{name: 'a'}, {name: 'b'}, {name: 'c'}, {name: 'd'}],
            theme: {
            isDark: false
            },
        }
    },
    computed: {
    cardHeight () {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '250'
            case 'sm': return '260'
            case 'md': return '350'
            case 'lg': return '350'
            case 'xl': return '350'
        }
    },
    },
    async mounted(){
    this.loading = true
    await this.getPullData()
    this.loading = false
    },
    methods: {
    drawer(){
        // return this.$store.commit('SET_DRAWER_ADMIN')
    },
    checkColor(count){
        return count ? 'red' : 'green'
    },
    close(){
        this.dialog = false
    },
    async utem(){
        this.dialog = true
    },
    async getPullData(){
        var reqBody = {
            'entity_id': 'GKI',
            'appl_id': this.$route.name,
            'path_header_id': this.$route.params.datastore
        }

        const respData = await menu.menu_item('', reqBody, false, false, false)

        if (respData.status === 200) {
            this.menus = respData.data
        }
    }
    }
  }
  </script>
  
  <style>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .9;
        position: absolute;
        width: 100%;
    }
    
    .entity{
        background-color: #A27842;
        color: #A27842;
    }
    
    .application_sec{
        background-color: #f56b3b;
        color: #f56b3b;
    }
    
    .group_master{
        background-color: #e95044;
        color: #e95044;
    }
    
    .cyan_unit{
        background-color: #c3d046;
        color: #c3d046;
    }
    
    .warehouse_coklat{
        background-color: #a27842;
        color: #a27842;
    }
    
    .group_right{
        background-color: #f5aa3b;
        color: #f5aa3b;
    }
    
    .menu_header{
        background-color: #54b857;
        color: #54b857;
    }
    
    .currency{
        background-color: #5581ad;
        color: #5581ad; 
    }
    
    .tax{
        background-color: #d351e9;
        color: #d351e9;  
    }
    
    .env_var{
        background-color: #4bb4c6;
        color: #4bb4c6;  
    }
    
    .menu_item{
        background-color: #ee69a7;
        color: #ee69a7; 
    }
    
    .env_conf{
        background-color: #0078d4;
        color: #0078d4; 
    }
  
    .users{
        background-color: #2eb3ec;
        color: #2eb3ec;
    }

    .user_groups{
        background-color: #0f5f81;
        color: #0f5f81;
    }

    .branch_office{
        background-color: #ea5044;
        color: #ea5044;
    }
</style>